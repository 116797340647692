<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div
        class="flex xs12"
        :class="[hasLocation ? 'sm4' : 'sm6']"
      >
        <va-card :title="$t('preaching_points.view')">
          <actions
            slot="actions"
            crud-links="preachings"
            controller="PreachingPoints"
            delete-route="preaching-points"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list :title="$t('tables.headings.name')">
            {{ preaching.name }}
          </text-list>
          <text-list
            v-if="preaching.leader"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.leader')"
            :label="preaching.leader.name"
            :to="{ name: 'usersView', params: {id: preaching.leader_id}}"
          />
          <text-list :title="$t('tables.headings.code')">
            {{ preaching.code }}
          </text-list>
          <text-list :title="$t('tables.headings.date')">
            {{ preaching.planted_date }}
          </text-list>
          <text-list :title="$t('tables.headings.address')">
            {{ preaching.address }}
          </text-list>
        </va-card>
      </div>
      <div
        class="flex xs12"
        :class="[hasLocation ? 'sm4' : 'sm6']"
      >
        <va-card :title="$t('preaching_points.attendance')">
          <text-list :title="$t('tables.headings.assistance_kids')">
            {{ preaching.assistance_kids }}
          </text-list>
          <text-list :title="$t('tables.headings.assistance_youngs')">
            {{ preaching.assistance_youngs }}
          </text-list>
          <text-list :title="$t('tables.headings.assistance_adults')">
            {{ preaching.assistance_adults }}
          </text-list>
        </va-card>
      </div>
      <div
        class="flex xs12 sm4"
        v-if="hasLocation"
      >
        <va-card :title="$t('preaching_points.location')">
          <text-list
            v-if="preaching.district"
            :condition="currentUser.can('Districts', 'view')"
            :title="$t('tables.headings.district')"
            :label="$t(preaching.district.name)"
            :to="{ name: 'districtsView', params: {id: preaching.district_id}}"
          />
          <text-list
            v-if="preaching.country"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.country')"
            :label="$t(preaching.country.name)"
            :to="{ name: 'countriesView', params: {id: preaching.country_id}}"
          />
          <text-list
            v-if="preaching.region"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.region')"
            :label="$t(preaching.region.name)"
            :to="{ name: 'regionsView', params: {id: preaching.region_id}}"
          />
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')

export default {
  name: 'preachings-view',
  components: {
    Actions, TextList,
  },
  data () {
    return {
      error: false,
      loading: false,
      preaching: {},
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    hasLocation () {
      return this.preaching.district || this.preaching.country || this.preaching.region
    },
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `preaching-points/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const preachingId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(preachingId))
      } catch (err) {
        // console.log('Error fetching preaching data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.preaching = { ...u.data.data }
    },
  },
}
</script>
